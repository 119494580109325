<script>
import LazyComponentMixin from '~/mixins/LazyComponentMixin'

export default {
  mixins: [LazyComponentMixin],
  data() {
    return {
      wrapperClass: 'FacebookWidgetPageLazy',
      componentFactory: () => import('./FacebookWidgetPage.vue'),
    }
  },
}
</script>
